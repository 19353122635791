import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Hero from "../components/Hero"

const ProductPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicProduct || {}
  const { lang, type, url } = pageData || {}
  const alternateLanguages = pageData.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout activeDoc={activeDoc}>
      <Seo
        title={pageData.data.title.text}
        description={pageData.data.description.text}
      />
      <Hero data={pageData.data} />
      {pageData.data.body &&
        pageData.data.body.map(section => (
          <Section key={uuidv4()} data={section} />
        ))}
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductPage

export const pageQuery = graphql`
  query productPageTemplateQuery($uid: String, $lang: String) {
    prismicProduct(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
        url
      }
      lang
      url
      type
      data {
        title {
          text
          raw
        }
        description {
          text
        }
        hero_icon
        hero_title {
          raw
        }
        hero_subtitle {
          raw
        }
        hero_image {
          url
          alt
        }
        button_1_text {
          text
        }
        button_1_link {
          uid
          type
          lang
          url
          link_type
        }
        button_2_text {
          text
        }
        button_2_link {
          uid
          type
          lang
          url
          link_type
        }
        selected_subject_1 {
          text
        }
        selected_subject_2 {
          text
        }
        body {
          ... on PrismicProductBodySectionStandard {
            slice_type
            primary {
              section_type
              heading {
                raw
              }
              subheading {
                raw
              }
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
            }
            items {
              column {
                raw
              }
            }
          }
          ... on PrismicProductBodySectionImage {
            slice_type
            primary {
              image_position
              heading {
                raw
              }
              subheading {
                raw
              }
              content {
                raw
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicProductBodySectionCta {
            slice_type
            primary {
              heading {
                raw
              }
              text {
                raw
              }
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
              selected_subject {
                text
              }
            }
            items {
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
              }
            }
          }
          ... on PrismicProductBodySectionFaq {
            slice_type
            primary {
              heading {
                raw
              }
              text {
                raw
              }
              text_below
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
            }
            items {
              question {
                text
              }
              answer {
                raw
              }
            }
          }
          ... on PrismicProductBodyImageGallery {
            slice_type
            primary {
              heading {
                raw
              }
              subheading {
                raw
              }
            }
            items {
              description {
                raw
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
